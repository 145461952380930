import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Slide from '@mui/material/Slide';
import GroupOrientation from "./GroupOrientation";
import Box from "@mui/material/Box";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Nachalo from "./Pages/1nachalo";
import NextPreviousComponent from "./NextPreviousComponent";
import Title from "./Pages/0title";
import Zaos1 from "./Pages/6zaos1";
import Zaos2 from "./Pages/7zaos2";
import Zaos3 from "./Pages/8zaos3";
import Zaos4 from "./Pages/9zaos4";
import Zaos5 from "./Pages/10zaos5";
import Zaos6 from "./Pages/11Zaos6";
import NextPreviousComponentMobile from "./NextPreviousComponentMobile";
import LongMenu from "./GroupOrientationMobile";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from "@mui/material/Container";
import Fatheradelgeim from "./Pages/2fatheradelgeim";
import Chronologyadelgeim from "./Pages/3chronologyadelgeim";
import NonLicet from "./Pages/12non-licet";
import Tver from "./Pages/13tver";
import Krasnousolsk from "./Pages/14krasnousolsk";
import Nizhnevartovsk from "./Pages/15nizhnevartovsk";
import Fast from "./Pages/17fast";
import Berdyansk from "./Pages/18berdyansk";
import Konec from "./Pages/19konec";
import Velsk from "./Pages/16velsk";
import HantyDeti from "./Pages/28_hanty-deti";
import Hanty1 from "./Pages/29_2012-2-hanty";
import Ufa1 from "./Pages/32_2012-11-03-ufa";
import Ufa2 from "./Pages/33_2010-04-1-ufa";
import Letter1 from "./Pages/38_2012-09-12";
import Strugglechurch from "./Pages/4strugglechurch";
import Velsk1 from "./Pages/20_2015-08-16-velsk";
import Velsk2 from "./Pages/21_2014-08-01-velsk";
import Velsk3 from "./Pages/22_2014-12-06-velsk";
import Velsk4 from "./Pages/23_2015-04-28-velsk";
import Velsk5 from "./Pages/24_2015-06-22-velsk";
import Velsk6 from "./Pages/25_2014-07-08-velsk";
import Velsk7 from "./Pages/26_2014-04-08-velsk2";
import Velsk8 from "./Pages/27_2014-04-08-velsk";
import Hanty2 from "./Pages/30_2013-05-14-hanty";
import Dvorkin from "./Pages/31_dvorkin-here";
import Ufa3 from "./Pages/34_2012-04-04-1-ufa";
import Ufa4 from "./Pages/35_2012-04-04-ufa";
import Ufa5 from "./Pages/36_2010-12-14-ufa1";
import Ufa6 from "./Pages/37_2010-12-14-ufa";
import Letter2 from "./Pages/39_6-11-2011";
import Letter3 from "./Pages/40_2010-05-2";
import Letter4 from "./Pages/41_2010-05-1";
import Letter5 from "./Pages/42_2008-03";
import Letter6 from "./Pages/43_2008-03-04";
import Letter7 from "./Pages/44_2008-02-23-1";
import Letter8 from "./Pages/45_2008-02-23-2";
import Letter9 from "./Pages/46_22-02-2008";
import Letter10 from "./Pages/47_13-03-2005";
import Letter11 from "./Pages/48_1-03-2005";
import Letter12 from "./Pages/49_18-04-2004";
import Ufa7 from "./Pages/50_2012-11-11-ufa";
import Ufa8 from "./Pages/51_2013-5-23-ufa";
import Ufa9 from "./Pages/52_2013-07-26-ufa";
import Hanty3 from "./Pages/53_2013-05-21-hanty";
import Velsk9 from "./Pages/54_2014-08-1-velsk";
import Zaostrov from "./Pages/5zaostrov";

export const pathsWithoutParts = [
    {"name": "/", "number": 0, "caption": "Главная страница"},
    {"name": "/nachalo/", "number": 1, "caption": "Предисловие"},
    {"name": "/fatheradelgeim/", "number": 2, "caption": "Дело о. Павла Адельгейма"},
    {"name": "/chronologyadelgeim/", "number": 3, "caption": "1. «Не дав ничего, Вы отняли у меня всё»"},
    {"name": "/strugglechurch/", "number": 4, "caption": "2. Борьба за приходской устав"},
    {"name": "/zaostrov/", "number": 5, "caption": "«Заостровское дело»"},
    {"name": "/zaos1/", "number": 6, "caption": "1. Новый епископ «пристреливается»"},
    {"name": "/zaos2/", "number": 7, "caption": "2. Доносчики и обвинители"},
    {"name": "/zaos3/", "number": 8, "caption": "3. Верните нашего брата - о. Павла!"},
    {"name": "/zaos4/", "number": 9, "caption": "4. Канонада всё явственнее"},
    {"name": "/zaos5/", "number": 10, "caption": "5. Захват Заостровья"},
    {"name": "/zaos6/", "number": 11, "caption": "6. После битвы"},
    {"name": "/non-licet/", "number": 12, "caption": "Не должно вам быть"},
    {"name": "/tver/", "number": 13, "caption": "1. О непричащении в Твери"},
    {"name": "/krasnousolsk/", "number": 14, "caption": "2. О непричащении в Красноусольском"},
    {"name": "/nizhnevartovsk/", "number": 15, "caption": "3. О непричащении в Нижневартовске"},
    {"name": "/velsk/", "number": 16, "caption": "4. О непричащении в Вельске"},
    {"name": "/fast/", "number": 17, "caption": "Преследование прот. Геннадия Фаста"},
    // {"name": "/berdyansk/", "number": 18, "caption": "Дело общины, против которой воевал спонсор"},
    {"name": "/konec/", "number": 18, "caption": "Заключение"}]
export const pathHidden = [
    {"name": "/2015-08-16-velsk/", "number": 20, "caption": "2015/08-16 Вельск"},
    {"name": "/2014-08-01-velsk/", "number": 21, "caption": "2014/08-01 Вельск"},
    {"name": "/2014-12-06-velsk/", "number": 22, "caption": "2014/12-06 Вельск"},
    {"name": "/2015-04-28-velsk/", "number": 23, "caption": "2015/04-28 Вельск"},
    {"name": "/2015-06-22-velsk/", "number": 24, "caption": "2015/06-22 Вельск"},
    {"name": "/2014-07-08-velsk/", "number": 25, "caption": "2014/07-08 Вельск"},
    {"name": "/2014-04-08-velsk2/", "number": 26, "caption": "2014/04-08 Вельск"},
    {"name": "/2014-04-08-velsk/", "number": 27, "caption": "2014/04-08 Вельск"},
    {"name": "/hanty-deti/", "number": 28, "caption": "Из воспоминаний Марии Никитиной"},
    {"name": "/2012-2-hanty/", "number": 29, "caption": "2012/2 - Нижневартовск"},
    {"name": "/2013-05-14-hanty/", "number": 30, "caption": "2013/05-14 Нижневартовск"},
    {"name": "/dvorkin-here/", "number": 31, "caption": "Из блогов"},
    {"name": "/2012-11-03-ufa/", "number": 32, "caption": "2012/11-03 Уфа"},
    {"name": "/2010-04-1-ufa/", "number": 33, "caption": "2010/04-1 Уфа"},
    {"name": "/2012-04-04-1-ufa/", "number": 34, "caption": "2012/04-04/1 Уфа"},
    {"name": "/2012-04-04-ufa/", "number": 35, "caption": "2012/04-04 Уфа"},
    {"name": "/2010-12-14-ufa1/", "number": 36, "caption": "2010/12-14 Уфа"},
    {"name": "/2010-12-14-ufa/", "number": 37, "caption": "Методическое пособие по миссионерской деятельности в Уфимской епархии"},
    {"name": "/2012-09-12/", "number": 38, "caption": "2012/09-12"},
    {"name": "/6-11-2011/", "number": 39, "caption": "2011/11-6"},
    {"name": "/2010-05-2/", "number": 40, "caption": "2010/05-2"},
    {"name": "/2010-05-1/", "number": 41, "caption": "2010/05-1"},
    {"name": "/2008-03/", "number": 42, "caption": "2008/03"},
    {"name": "/2008-03-04/", "number": 43, "caption": "2008/03/04"},
    {"name": "/2008-02-23-1/", "number": 44, "caption": "2008/02/23-1"},
    {"name": "/2008-02-23-2/", "number": 45, "caption": "2008/02/23-2"},
    {"name": "/22-02-2008/", "number": 46, "caption": "2008/02/22"},
    {"name": "/13-03-2005/", "number": 47, "caption": "2005/03/13"},
    {"name": "/1-03-2005/", "number": 48, "caption": "2005/03/01"},
    {"name": "/18-04-2004/", "number": 49, "caption": "2004/02/18"},
    {"name": "/2012-11-11-ufa/", "number": 50, "caption": "2012/11-11 Красноусольск"},
    {"name": "/2013-5-23-ufa/", "number": 51, "caption": "2013/5-23 Уфа"},
    {"name": "/2013-07-26-ufa/", "number": 52, "caption": "2013/07-26 Уфа"},
    {"name": "/2013-05-21-hanty/", "number": 53, "caption": "2013/05-21 Нижневартовск"},
    {"name": "/2014-08-1-velsk/", "number": 54, "caption": "2014/08-1 Вельск"}
]
export const paths = [
    {
        "name": "/",
        "element": <Title/>
    }, {
        "name": "/nachalo/",
        "element": <Nachalo/>
    },
    {
        "name": "/fatheradelgeim/",
        "element": <Fatheradelgeim/>
    },
    {
        "name": "/chronologyadelgeim/",
        "element": <Chronologyadelgeim/>
    },
    {
        "name": "/strugglechurch/",
        "element": <Strugglechurch/>
    },
    {
        "name": "/zaostrov/",
        "element": <Zaostrov/>
    },
    {
        "name": "/zaos1/",
        "element": <Zaos1/>
    },
    {
        "name": "/zaos2/",
        "element": <Zaos2/>
    },
    {
        "name": "/zaos3/",
        "element": <Zaos3/>
    },
    {
        "name": "/zaos4/",
        "element": <Zaos4/>
    },
    {
        "name": "/zaos5/",
        "element": <Zaos5/>
    },
    {
        "name": "/zaos6/",
        "element": <Zaos6/>
    },

    {
        "name": "/non-licet/",
        "element": <NonLicet/>
    },
    {
        "name": "/tver/",
        "element": <Tver/>
    },
    {
        "name": "/krasnousolsk/",
        "element": <Krasnousolsk/>
    },
    {
        "name": "/nizhnevartovsk/",
        "element": <Nizhnevartovsk/>
    },
    {
        "name": "/velsk/",
        "element": <Velsk/>
    },
    {
        "name": "/fast/",
        "element": <Fast/>
    },
    // {
    //     "name": "/berdyansk/",
    //     "element": <Berdyansk/>
    // },
    {
        "name": "/konec/",
        "element": <Konec/>
    },
    {
        "name": "/2015-08-16-velsk/",
        "element": <Velsk1/>
    },
    {
        "name": "/2014-08-01-velsk/",
        "element": <Velsk2/>
    },
    {
        "name": "/2014-12-06-velsk/",
        "element": <Velsk3/>
    },
    {
        "name": "/2015-04-28-velsk/",
        "element": <Velsk4/>
    },
    {
        "name": "/2015-06-22-velsk/",
        "element": <Velsk5/>
    },
    {
        "name": "/2014-07-08-velsk/",
        "element": <Velsk6/>
    },
    {
        "name": "/2014-04-08-velsk2/",
        "element": <Velsk7/>
    },
    {
        "name": "/2014-04-08-velsk/",
        "element": <Velsk8/>
    },
    {
        "name": "/hanty-deti/",
        "element": <HantyDeti/>
    },
    {
        "name": "/2012-2-hanty/",
        "element": <Hanty1/>
    },
    {
        "name": "/2013-05-14-hanty/",
        "element": <Hanty2/>
    },
    {
        "name": "/dvorkin-here/",
        "element": <Dvorkin/>
    },
    {
        "name": "/2012-11-03-ufa/",
        "element": <Ufa1/>
    },
    {
        "name": "/2010-04-1-ufa/",
        "element": <Ufa2/>
    },
    {
        "name": "/2012-04-04-1-ufa/",
        "element": <Ufa3/>
    },
    {
        "name": "/2012-04-04-ufa/",
        "element": <Ufa4/>
    },
    {
        "name": "/2010-12-14-ufa1/",
        "element": <Ufa5/>
    },
    {
        "name": "/2010-12-14-ufa/",
        "element": <Ufa6/>
    },
    {
        "name": "/2012-09-12/",
        "element": <Letter1/>
    },
    {
        "name": "/6-11-2011/",
        "element": <Letter2/>
    },
    {
        "name": "/2010-05-2/",
        "element": <Letter3/>
    },
    {
        "name": "/2010-05-1/",
        "element": <Letter4/>
    },
    {
        "name": "/2008-03/",
        "element": <Letter5/>
    },
    {
        "name": "/2008-03-04/",
        "element": <Letter6/>
    },
    {
        "name": "/2008-02-23-1/",
        "element": <Letter7/>
    },
    {
        "name": "/2008-02-23-2/",
        "element": <Letter8/>
    },
    {
        "name": "/22-02-2008/",
        "element": <Letter9/>
    },
    {
        "name": "/13-03-2005/",
        "element": <Letter10/>
    },
    {
        "name": "/1-03-2005/",
        "element": <Letter11/>
    },
    {
        "name": "/18-04-2004/",
        "element": <Letter12/>
    },
    {
        "name": "/2012-11-11-ufa/",
        "element": <Ufa7/>
    },
    {
        "name": "/2013-5-23-ufa/",
        "element": <Ufa8/>
    },
    {
        "name": "/2013-07-26-ufa/",
        "element": <Ufa9/>
    },
    {
        "name": "/2013-05-21-hanty/",
        "element": <Hanty3/>
    },
    {
        "name": "/2014-08-1-velsk/",
        "element": <Velsk9/>
    }
    ]

function HideOnScroll(props) {
    const {children, window} = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
}

function isMobile() {
    let check = false;
    (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}


export default function Main(props) {
    if (isMobile()) {
        return (
            <React.Fragment>
                <CssBaseline/>
                <HideOnScroll {...props}>
                    <AppBar>
                        <Typography variant="h6" component="div">
                            <LongMenu></LongMenu>
                        </Typography>
                    </AppBar>
                </HideOnScroll>
                <Toolbar/>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    flexWrap: 'nowrap',
                    alignContent: 'top'
                }}>
                    <BrowserRouter initialEntries={['/']} initialIndex={0}>
                        <Container>
                            <div style={{height: "100vh", overflow: "auto", overflowY: "auto"}}>
                                <NextPreviousComponentMobile>
                                </NextPreviousComponentMobile>
                                <Routes>
                                    {paths.map(path => {
                                        return <Route path={path.name} element={path.element}/>
                                    })}
                                    <Route path="*" element={paths[0].element}/>
                                </Routes>
                                <NextPreviousComponentMobile>
                                </NextPreviousComponentMobile>
                            </div>
                        </Container>
                    </BrowserRouter>
                </Box>
            </React.Fragment>
        );
    }
    let some = pathsWithoutParts.some(path => path.name === window.location.pathname);
    let someHidden = pathHidden.some(path => path.name === window.location.pathname);
    if (someHidden) {
        return (
            <React.Fragment>
                <CssBaseline/>
                <HideOnScroll {...props}>
                    <AppBar>
                        <Typography variant="h6" component="div">
                            <LongMenu></LongMenu>
                        </Typography>
                    </AppBar>
                </HideOnScroll>
                <Toolbar/>
                <table width='1024px' align='center'>
                    <tr>
                        <td>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                flexWrap: 'nowrap',
                                alignContent: 'top'
                            }}>
                                <BrowserRouter initialEntries={['/']} initialIndex={0}>
                                    <Container>
                                        <div style={{height: "100vh", overflow: "auto", overflowY: "auto"}}>
                                            <Routes>
                                                {paths.map(path => {
                                                    return <Route path={path.name} element={path.element}/>
                                                })}
                                                <Route path="*" element={paths[0].element}/>
                                            </Routes>
                                        </div>
                                    </Container>

                                </BrowserRouter>
                            </Box>
                        </td>
                    </tr>
                </table>


                {/*</div>*/}
            </React.Fragment>

        );
    }
    if (some){
        return (
            <React.Fragment>
                <CssBaseline/>
                <HideOnScroll {...props}>
                    <AppBar>
                        <Typography variant="h6" component="div">
                            <LongMenu></LongMenu>
                        </Typography>
                    </AppBar>
                </HideOnScroll>
                <Toolbar/>
                <table width='1024px' align='center'>
                    <tr>
                        <td>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'left',
                                flexWrap: 'nowrap',
                                alignContent: 'top'
                            }}>
                                <BrowserRouter initialEntries={['/']} initialIndex={0}>
                                    <div>
                                        <GroupOrientation>
                                        </GroupOrientation>
                                    </div>
                                    <Container>
                                        <div style={{height: "100vh", overflow: "auto", overflowY: "auto"}}>
                                            <NextPreviousComponent>
                                            </NextPreviousComponent>
                                            <Routes>
                                                {paths.map(path => {
                                                    return <Route path={path.name} element={path.element}/>
                                                })}
                                                <Route path="*" element={paths[0].element}/>
                                            </Routes>
                                            <NextPreviousComponent>
                                            </NextPreviousComponent>
                                        </div>
                                    </Container>

                                </BrowserRouter>
                            </Box>
                        </td>
                    </tr>
                </table>


                {/*</div>*/}
            </React.Fragment>

        );
    }
}
